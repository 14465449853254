(function($) {
    'use strict';

    // (function panelAutoHeight() {
    //
    //     var panel5 = $('#panel5');
    //
    //     function update() {
    //         var newHeight = panel5.find('.container').outerHeight();
    //         panel5.css({ height: newHeight + 200 });
    //     }
    //
    //     $(window).on('resize load', update);
    // })();

    function registerEventTrackingOnIframe(iframe) {
        var options = {
            id: parseInt($(iframe).attr('data-video')),
            width: parseInt($(iframe).attr('width'))
        };

        var player = new Vimeo.Player($(iframe).attr('id'),options);
        var preExistingVimeoObject = $(iframe).data('vimeo-player');
        var videoTitle = '[untitled]';
        var videoId = 0;
        var fireEventOnPercentages = [25, 50, 75, 95, 100];

        function resetEventPercentages() {
            player.getVideoTitle().then(function(title) {
                videoTitle = title;
            });

            player.getVideoId().then(function(id) {
                videoId = id;
            });
        }

        if(preExistingVimeoObject === undefined) { // only set event handlers on iframes that don't already have them

            resetEventPercentages();

            player.on('timeupdate', function(data) {

                console.log('percent completed: ' + data.percent);

                if(fireEventOnPercentages.length && data.percent * 100 >= fireEventOnPercentages[0]) {
                    var percentCompleted = fireEventOnPercentages.shift();

                    var event = {
            			event: 'gaEvent',
            			eventCategory: videoTitle,
            			eventAction: 'Percentage Complete',
            			eventLabel: percentCompleted
            		};

                    $(window).trigger('analytic.event', event);
                }
            });

            player.on('finish', function(){
                player.unload().then(function() {
                    console.log("Video Reset After Finish");
                });

                if ($('html').hasClass('ie')) {
                    $(this).clone().appendTo($(this).parent());
                    $(this).remove();
                }
            });

            player.on('ended', resetEventPercentages); // ended *should* fire when swapping out iframe urls hopefully, otherwise we'll need to call it manually
        }

        var methods = {
            vimeoPlayer: player,
            reset: resetEventPercentages,
        };

        $(iframe).data('vimeo-player', methods);

        return methods;
    }

    //$(window).trigger('analytic.event', {'category': 'link-panel-consumer-lighting-click', 'action': 'click'});
    function sendAnalyticEvent (ev, data) {
        /*{
            event: "gaEvent",
            eventCategory: "Better Light - What if a City Woke Up With Better Light?",
            eventAction: "Percentage Complete",
            eventLabel: 25
        } */

        var event = {
            event: 'gaEvent',
            eventCategory: data.category,
            eventAction: data.action,
            eventLabel: data.label
        };

        console.log(event);

        if(dataLayer) {
            dataLayer.push(event);
        }

        //if(ga) {
            //ga("send", "event", event.eventCategory, event.eventAction, event.eventLabel, event.value);
        //}
    }

    $(window).on('analytic.event', sendAnalyticEvent);


    // Documentation for ScrollMagic here - http://scrollmagic.io/
    var SM = (function() {

        var controller = new ScrollMagic.Controller();

        // Get the height of the Diode graphic
        var diodeHeight = $('#half-diode').height();

        // Get the offset of the middle of the diode from the bottom of the screen.
        var diodeOffset = (window.innerHeight - $('#half-diode').offset().top) - (diodeHeight/2);

        function getWindowHeight() {
            return window.innerHeight;
        }

        function getPanelHeight(panelSelector) {
            panelSelector = panelSelector === undefined ? $('#panel1') : $(panelSelector);
            return function() {
                return panelSelector.outerHeight();
            };
        }

        // Loop for adding scroll events with Panels as trigger elements
        $('.panel').each(function(index, el){

            $(window).load(function() {
                // move panel active state inside window load function to prevent jerky animations while the page loads.

                // Set active class on panels to initiate animations
                var panelActiveScene = new ScrollMagic.Scene({triggerElement: "#panel" + (index + 1), duration: getPanelHeight("#panel" + (index + 1))})
                .setClassToggle("#panel" + (index + 1) , "active")

                //set trigger hook to 30% from the top of the window
                .triggerHook(0.3)
                .addTo(controller);

            });


            // Set active state on navigation item as panel comes into view
            var navActiveScene = new ScrollMagic.Scene({triggerElement: "#panel" + (index + 1), duration: getPanelHeight("#panel" + (index + 1))})
            .setClassToggle(".nav-item-" + index + " a" , "active")

            //set trigger hook to 50% from the top of the window
            .triggerHook(0.5)
            .addTo(controller);



            var color = $(this).attr('data-diode-color');

            // Update color of the diode based on it's transition to Panel 2
            var diodeColorScene = new ScrollMagic.Scene({triggerElement: "#panel" + (index + 1), duration: getPanelHeight("#panel" + (index + 1))})

            .setClassToggle("#half-diode", color)

            // use diode offset from the bottom of the screen as trigger offset
            .offset(diodeOffset)

            // set the trigger hook to the bottom of the screen
            .triggerHook(1)
            .addTo(controller);


            // Set the animate class on each diode piece when it transitions to panel 2
            var diodeAnimationScene = new ScrollMagic.Scene({triggerElement: "#panel" + (index + 1)})

            .setClassToggle("#top, #middle, #bottom", "animate")

            // use diode offset from the bottom of the screen as trigger offset
            .offset(diodeOffset)

            // set the trigger hook to the bottom of the screen
            .triggerHook(1)
            .addTo(controller);
        });

        // Add start class to panel5 to trigger the news item animations.
        var newsPanelStartScene = new ScrollMagic.Scene({triggerElement: "#panel5", duration: getPanelHeight("#panel5")})

        .setClassToggle("#panel5" , "start")

        //set trigger hook to 50% from the top of the window
        .triggerHook(0.3)
        .addTo(controller);

        // Loop for adding scroll events with bullet nav items as trigger elements
        $('#bullet-nav a').each(function(index, el) {

            // get offset of navigation from the bottom of the screen.
            var navOffset = window.innerHeight - $(this).offset().top;

            var navIndex = index;

            $('[data-nav-theme="dark"]').each(function(){

                var id = $(this).attr('id');

                // Set navigation to dark theme for panels with data-nav-theme set to dark
                var navThemeScene = new ScrollMagic.Scene({triggerElement: "#" + id, duration: getPanelHeight("#" + id)})

                .setClassToggle(".nav-item-" + navIndex, "dark")
                .offset(navOffset)
                .triggerHook(1)
                .addTo(controller);
            });

        });

        // Remove animate class from Diode section, once the animation has ended, so we can trigger the animation again on the next transition
        $("#bottom").on(
            "webkitAnimationEnd oanimationend msAnimationEnd animationend",
            function() {
                var element = document.getElementById("bottom");
                element.classList.remove('animate');
            }
        );

        // Remove animate class from Diode section, once the animation has ended, so we can trigger the animation again on the next transition
        $("#middle").on(
            "webkitAnimationEnd oanimationend msAnimationEnd animationend",
            function() {
                var element = document.getElementById("middle");
                element.classList.remove('animate');
            }
        );

        // Remove animate class from Diode section, once the animation has ended, so we can trigger the animation again on the next transition
        $("#top").on(
            "webkitAnimationEnd oanimationend msAnimationEnd animationend",
            function() {
                var element = document.getElementById("top");
                element.classList.remove('animate');
            }
        );

        // Remove start class from Panel 6, once the animation has ended, so only the original 3 news items animate in
        $("body").on(
            "webkitAnimationEnd oanimationend msAnimationEnd animationend",
            ".slick-active",
            function() {
                var element = document.getElementById("panel5");
                element.classList.remove('start');
            }
        );

    })();

    // Slick Slider documentation here - http://kenwheeler.github.io/slick/

    // Video Carousel Init for Slick Slider
    var videoCarousel = (function() {
        $('.video-carousel').slick({
            prevArrow: '#panel3-prev',
            nextArrow: '#panel3-next',
            slide: '.slide',
        });
    })();

    // Environment Carousel Init for Slick Slider
    var environmentCarousel = (function() {
        $('.environment-carousel').slick({
            prevArrow: '#panel2-prev',
            nextArrow: '#panel2-next',
            slide: '.slide',
            slidesToShow: 3,
            vertical: true,
            responsive: [
                {
                  breakpoint: 769,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
              },
            ]
        });
    })();

    // News Carousel Init for Slick Slider
    var newsCarousel = (function() {
        $('.news-carousel').slick({
            prevArrow: '#panel5-prev',
            nextArrow: '#panel5-next',
            slide: '.slide',
            slidesToShow: 3,
            responsive: [
                {
                  breakpoint: 1159,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
              },
              {
                breakpoint: 889,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
        });
    })();

    // News Carousel Init for Slick Slider
    var panel1Carousel = (function() {
        $('.panel-one-carousel').slick({
            prevArrow: '#panel1-prev',
            nextArrow: '#panel1-next',
            slide: '.slide',
            slidesToShow: 3,
            responsive: [
                {
                  breakpoint: 1159,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
              },
              {
                breakpoint: 889,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
        });
    })();

    // Vimeo Javascript Documentation - https://developer.vimeo.com/player/js-api

    // Auto play / stop video when modal window is opened or closed
    var videoPlayback = (function() {
        $('.video-modal').on('shown.bs.modal', function (e) {

            var $iframe = $(e.target).find('.videoPlayer');

            var vimeoController = registerEventTrackingOnIframe($iframe[0]);

            vimeoController.vimeoPlayer.play();
        });

        $('.video-modal').on('hide.bs.modal', function (e) {
            var video = $(e.target).find('.videoPlayer'),
                videoData = video.data('vimeo-player'),
                videoController = videoData.vimeoPlayer;

            // stop the video and reload it so it plays from the beginning next time the modal is opened.
            videoController.unload().then(function() {
                console.log('Video Reset');
            });
        });

        $('.videoPlayer').each(function(index, el){


            $(this).on('finish', function(){
                $(this).vimeo('unload');

                if ($('html').hasClass('ie')) {
                    $(this).clone().appendTo($(this).parent());
                    $(this).remove();
                }
            });

        });


    })();

    // Smooth scroll function for anchors
    $(function() {
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });
    });

})(jQuery);
